import React from 'react'
import Menu from '../../common/Menu/Menu'
import TopFooter from '../../common/TopFooter/TopFooter'
import Footer from '../../common/Footer/Footer'
import CareerBanner from './CareersBanner'
import CareersTab from './CareersTab'
import LifeAtAlok from './LifeAtAlok'
import PerksAndBenefits from './PerksAndBenefits'
import Jobs from './Jobs'
import JobForm from './JoinForm'
import LatestBlog from '../../Home/LastedBlog'
import Faq from './Faq'
import Meta from '../../Meta'

function Careers() {
  return (
      <>
      <Meta
                  title="Corporate Social Responsibility Policy - Alok Masterbatches"
                  description="Learn about Alok Masterbatches' Corporate Social Responsibility (CSR) policies, including healthcare, education, and sustainable development initiatives."
                />
          <Menu />
          <CareerBanner/>
          <CareersTab/>
          <LifeAtAlok/>
          <PerksAndBenefits/>
          <Jobs/>
          {/* <LatestBlog /> */}
          {/* <Faq/> */}
          <JobForm/>
          <TopFooter />
          <Footer />
      </>
  )
}

export default Careers