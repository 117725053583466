import React from "react";
import Footer from "../common/Footer/Footer";
import Menu from "../common/Menu/Menu";
import LastedBlog from "./LastedBlog";
import TopAbout from "./TopAbout";
import TopCtaArea from "./TopCtaArea";
import TopFooter from "../common/TopFooter/TopFooter";
import HomeSlider from "./HomeSlider";
import OurBlogs from "./OurBlogs";
import ContactForm from "./ContactForm";
import Achievements from "./Achievements";
import Calendar from "../common/CustomCalender";
import NewsSection from "./NewsSection";
import Meta from "../Meta";

const Home = () => {
  return (
    <>
    <Meta
                title="Masterbatch Manufacturers and Suppliers - Alok Masterbatches"
                description="Alok Masterbatches is a leading manufacturer and supplier of high-quality masterbatches, offering color and additive solutions for diverse industries. Get in touch today!"
              />
      <Menu />
      <HomeSlider />
      <TopAbout />
      <Achievements/>
      <TopCtaArea />
      {/* <OurBlogs/> */}
      {/* <NewsSection/> */}
      {/* <LastedBlog /> */}
      {/* <Calendar/> */}
      {/* <Gallery /> */}
      {/* <Team /> */}
      {/* <Feature /> */}
      {/* <Testimonial /> */}
      {/* <Brands /> */}
      {/* <FullScreenBtn /> */}
      <ContactForm/>
      <TopFooter />
      <Footer />
    </>
  );
};

export default Home;
