import React from 'react'
import Menu from '../../common/Menu/Menu'
import TopFooter from '../../common/TopFooter/TopFooter'
import Footer from '../../common/Footer/Footer'
import CommonBanner from '../../common/CommonBanner'
import { Link } from 'react-router-dom'
import ContactCard from '../Industries/ContactCard'
import CommonContactCard from '../../common/CommonContactCard'
import Meta from '../../Meta'

function Privacy() {
  return (
    <>
    <Meta
                title="Corporate Social Responsibility Policy - Alok Masterbatches"
                description="Learn about Alok Masterbatches' Corporate Social Responsibility (CSR) policies, including healthcare, education, and sustainable development initiatives."
              />
      <Menu />
      <CommonBanner imageUrl={'/assets/img/sustainability.png'} title={"Privacy Policy"} subtext={""} />
     
       
      <div className="container mt-5">
      <h1 className="text-black text-center mb-4 text-md mt-40">Privacy Policy for ALOK Masterbatches</h1>

      <div className="mb-3">
        <p><strong>Effective Date:</strong> 19/10/2024</p>
        <p>
          At ALOK Masterbatches, we are committed to respecting your privacy and
          protecting your personal data. This Privacy Policy outlines how we handle
          any personal information we may collect when you interact with us and
          ensures compliance with the General Data Protection Regulation (GDPR).
          Please read this Privacy Policy carefully to understand how we collect,
          use, and protect your information.
        </p>
      </div>

      <div className="mb-4">
        <h2 className="text-black text-sm">1. Contact Details</h2>
        <p>
          If you have any questions or concerns regarding this Privacy Policy or
          how your personal information is handled, please contact us at:
        </p>
        <address>
          <strong>ALOK Masterbatches</strong><br />
          ALOK MASTERBATCHES PVT. LTD.
          227, Okhla Industrial Estate, Phase III, New Delhi -110020.<br />
          <strong>Phone:</strong>{' '} 
          <a href="tel:+91114161224447">+91-11-41612244-47</a>
          <br />
          <strong>Email:</strong>{' '}
          <a href="mailto:info@alokindustries.com">info@alokindustries.com</a>
          <br />
          <strong>Website:</strong>{' '}
          <a href="https://alokmasterbatches.com">https://alokmasterbatches.com</a>
        </address>
      </div>

      <div className="mb-4">
        <h2 className="text-black text-sm">2. Data Collection</h2>
        <p>
          ALOK Masterbatches does not collect personal data automatically from
          visitors. We only collect data that you voluntarily provide to us through
          forms or other means of communication when you initiate contact with us.
        </p>
        <p>The types of data we may collect from you include:</p>
        <ul>
          <li>Name</li>
          <li>Contact details (e.g., email address, phone number)</li>
          <li>Company name (if applicable)</li>
          <li>Any other information you choose to provide</li>
        </ul>
        <p>
          We do not collect or track any personal data using cookies, tracking
          technologies, or any automated systems.
        </p>
      </div>

      <div className="mb-4">
        <h2 className="text-black text-sm">3. Data Use</h2>
        <p>
          The personal data you provide will be used solely for communication
          purposes, in response to your inquiries, and to facilitate any request or
          service initiated by you. We will not use your data for any other
          purposes, such as marketing or profiling, without your explicit consent.
        </p>
      </div>

      <div className="mb-4">
        <h2 className="text-black text-sm">4. Data Security</h2>
        <p>
          We take the security of your personal data seriously and implement all
          necessary technical and organizational measures to protect it from
          unauthorized access, alteration, disclosure, or destruction. Our data
          security protocols comply with the latest GDPR standards, ensuring that
          your personal information is stored and processed securely.
        </p>
      </div>

      <div className="mb-4">
        <h2 className="text-black text-sm">5. Data Sharing</h2>
        <p>
          We do not share your personal data with any third parties, except as
          required by law or in the event of legal processes. ALOK Masterbatches
          does not sell or trade personal data for any commercial purposes. In
          cases where we may need to collaborate with service providers (e.g., for
          website maintenance or communication services), we ensure that any shared
          data is minimal and that these providers comply with GDPR regulations.
        </p>
      </div>

      <div className="mb-4">
        <h2 className="text-black text-sm">6. User Rights</h2>
        <p>As a data subject under the GDPR, you have the following rights regarding your personal data:</p>
        <ul>
          <li><strong>Right to Access:</strong> You may request a copy of the personal data we hold about you.</li>
          <li><strong>Right to Rectification:</strong> You may ask us to correct any inaccurate or incomplete data.</li>
          <li><strong>Right to Erasure (Right to be Forgotten):</strong> You may request the deletion of your personal data in certain circumstances.</li>
          <li><strong>Right to Restrict Processing:</strong> You may request that we limit the processing of your data under certain conditions.</li>
          <li><strong>Right to Data Portability:</strong> You may request a copy of your personal data in a machine-readable format to transfer it to another organization.</li>
          <li><strong>Right to Object:</strong> You may object to the processing of your personal data in certain situations.</li>
          <li><strong>Right to Withdraw Consent:</strong> If you have given consent for data processing, you may withdraw this consent at any time.</li>
        </ul>
        <p>
          To exercise any of these rights, please contact us at the details
          provided above. We will respond to your request within the legally
          required timeframe and ensure that your rights are respected.
        </p>
      </div>

      <div className="mb-4">
        <h2 className="text-black text-sm">7. Policy Changes</h2>
        <p>
          ALOK Masterbatches reserves the right to update or modify this Privacy
          Policy at any time in response to evolving legal, technical, or business
          developments. In the event of significant changes, we will notify you by
          posting an updated version on our website and, where appropriate, by
          other means of communication (e.g., email). Please ensure that you review
          this policy periodically to stay informed of any updates.
        </p>
        <p>
          This Privacy Policy is effective as of [Insert Date] and was last updated
          on [Insert Date].
        </p>
        <p>
          By continuing to use our services or interact with us, you acknowledge
          and accept the terms of this Privacy Policy.
        </p>
      </div>
    </div>

      <div className='mt-130'>
      <CommonContactCard />
      </div>
      <TopFooter />
      <Footer />
    </>
  )
}

export default Privacy