import React from 'react'
import Menu from '../../common/Menu/Menu'
import TopFooter from '../../common/TopFooter/TopFooter'
import Footer from '../../common/Footer/Footer'
import CommonBanner from '../../common/CommonBanner'
import { Link } from 'react-router-dom'
import ContactCard from '../Industries/ContactCard'
import CommonContactCard from '../../common/CommonContactCard'
import Meta from '../../Meta'

function TermsAndCondition() {
  return (
    <>
      <Meta
        title="Corporate Social Responsibility Policy - Alok Masterbatches"
        description="Learn about Alok Masterbatches' Corporate Social Responsibility (CSR) policies, including healthcare, education, and sustainable development initiatives."
      />
      <Menu />
      <CommonBanner imageUrl={'/assets/img/sustainability.png'} title={"Terms And Condition"} subtext={""} />
      <div className='container'>

      <div className="container mt-5">
      <h1 className="text-black text-center mb-4 text-md mt-40">Terms and Conditions for ALOK Masterbatches</h1>

      <div className="mb-3">
        <p><strong>Effective Date:</strong> 19/10/2024</p>
        <p>
          Welcome to ALOK Masterbatches! These Terms and Conditions govern your use of our website,
          services, and any communications or transactions conducted with us. Please read these
          terms carefully before using our website or services. By accessing or using our website and services,
          you agree to comply with and be bound by the following terms and conditions.
        </p>
      </div>

      <div className="mb-4">
        <h2 className="text-black text-sm">1. General Information</h2>
        <p>
          These Terms and Conditions apply to all visitors, users, and customers (referred to as “you” or “users”)
          of ALOK Masterbatches (referred to as “we,” “us,” or “our”). By using our website, submitting inquiries,
          or engaging with our services, you acknowledge and agree to these terms.
        </p>
      </div>

      <div className="mb-4">
        <h2 className="text-black text-sm">2. Use of Our Website</h2>
        <ul>
          <li>The content on our website is provided for general information purposes only. It is subject to change without notice.</li>
          <li>
            You agree to use the website only for lawful purposes. You are prohibited from violating or attempting to violate
            the security of the website or using the website in a way that infringes on the rights of others.
          </li>
          <li>
            We do not guarantee the accuracy, completeness, or timeliness of the information provided on our website.
            Any reliance you place on such information is strictly at your own risk.
          </li>
        </ul>
      </div>

      <div className="mb-4">
        <h2 className="text-black text-sm">3. Intellectual Property</h2>
        <ul>
          <li>
            All content on the ALOK Masterbatches website, including but not limited to text, graphics, logos,
            images, and software, is the intellectual property of ALOK Masterbatches and is protected by applicable copyright,
            trademark, and intellectual property laws.
          </li>
          <li>
            You may not reproduce, distribute, modify, or use any part of our website or its content without our
            express written permission, except for your personal, non-commercial use.
          </li>
        </ul>
      </div>

      <div className="mb-4">
        <h2 className="text-black text-sm">4. Data Collection and Privacy</h2>
        <p>
          By using our website and submitting any forms or inquiries, you agree to our Privacy Policy. ALOK
          Masterbatches does not automatically collect personal data unless it is voluntarily submitted by you. We only
          collect and process the data necessary to respond to your inquiries or provide the services requested. For more details
          on how we handle personal data, please refer to our Privacy Policy.
        </p>
      </div>

      <div className="mb-4">
        <h2 className="text-black text-sm">5. Communication</h2>
        <p>
          When you submit forms, contact us, or otherwise communicate with us through our website, you agree to receive
          communication from us. We will use the contact information you provide solely to respond to your inquiries
          and provide any services you request. We will not send unsolicited marketing communications without your consent.
        </p>
      </div>

      <div className="mb-4">
        <h2 className="text-black text-sm">6. Limitations of Liability</h2>
        <ul>
          <li>
            ALOK Masterbatches shall not be held liable for any direct, indirect, incidental, consequential, or punitive
            damages arising from your use of our website or services, including any errors or omissions in content, interruptions
            in service, or inaccuracies in the information provided.
          </li>
          <li>
            While we strive to ensure that our website is free of viruses or other harmful components, we cannot guarantee this.
            Users are responsible for taking necessary precautions to protect their own systems and data.
          </li>
        </ul>
      </div>

      <div className="mb-4">
        <h2 className="text-black text-sm">7. External Links</h2>
        <p>
          Our website may contain links to third-party websites for your convenience. These links are provided for reference
          purposes only, and ALOK Masterbatches does not endorse or take responsibility for the content, privacy practices,
          or availability of these external websites. Your use of third-party websites is at your own risk.
        </p>
      </div>

      <div className="mb-4">
        <h2 className="text-black text-sm">8. Modification of Services and Terms</h2>
        <p>
          ALOK Masterbatches reserves the right to modify or discontinue our services, website content, or these
          Terms and Conditions at any time without prior notice. Changes to these terms will be posted on this page with an updated
          effective date. It is your responsibility to review these Terms and Conditions periodically for any updates or changes.
        </p>
        <p>
          By continuing to use our website or services after changes are posted, you agree to the updated terms.
        </p>
      </div>

      <div className="mb-4">
        <h2 className="text-black text-sm">9. Indemnification</h2>
        <p>
          You agree to indemnify, defend, and hold ALOK Masterbatches and its affiliates, employees, agents, and licensors harmless
          from and against any claims, liabilities, damages, losses, or expenses, including reasonable legal fees, arising out of your
          violation of these Terms and Conditions or your use of our website or services.
        </p>
      </div>

      <div className="mb-4">
        <h2 className="text-black text-sm">10. Governing Law</h2>
        <p>
          These Terms and Conditions shall be governed by and construed in accordance with the laws of [Insert Jurisdiction], without
          regard to its conflict of law principles. Any disputes arising from or related to these terms shall be subject to the
          exclusive jurisdiction of the courts in [Insert Jurisdiction].
        </p>
      </div>

      <div className="mb-4">
        <h2 className="text-black text-sm">11. Contact Information</h2>
        <p>
          If you have any questions about these Terms and Conditions or require further clarification, please contact us at:
        </p>
        <address>
        <strong>ALOK Masterbatches</strong><br />
          ALOK MASTERBATCHES PVT. LTD.
          227, Okhla Industrial Estate, Phase III, New Delhi -110020.<br />
          <strong>Phone:</strong>{' '} 
          <a href="tel:+91114161224447">+91-11-41612244-47</a>
          <br />
          <strong>Email:</strong>{' '}
          <a href="mailto:info@alokindustries.com">info@alokindustries.com</a>
          <br />
          <strong>Website:</strong>{' '}
          <a href="https://alokmasterbatches.com">https://alokmasterbatches.com</a>
        </address>
      </div>

      <div className="mb-4">
        <h2 className="text-black text-sm">12. Policy Effective Date</h2>
        <p>
          These Terms and Conditions are effective as of [Insert Date] and were last updated on [Insert Date].
        </p>
      </div>
    </div>
    
      </div>
      <div className='mt-130'>
      <CommonContactCard />
      </div>
      <TopFooter />
      <Footer />
    </>
  )
}

export default TermsAndCondition