import React from 'react'
import Menu from '../../common/Menu/Menu'
import TopFooter from '../../common/TopFooter/TopFooter'
import Footer from '../../common/Footer/Footer'
import IntroBanner from '../../common/IntroBanner'
import ProductContact from './ProductContact'
import ProductsSidebar from '../Products/ProductsSideBar'
import OtherProducts from '../Products/OurProducts'
import IndustriesFooter from '../Industries/IndustriesFooter'
import Meta from '../../Meta'

function Black() {
  return (
      <>
      <Meta
                  title="Black masterbatch - Black masterbatch supplier​"
                  description="Alok's Black Masterbatches offer superior performance, durability, and quality. Trusted supplier of black masterbatch solutions for diverse industrial applications.."
                />
      <Menu />

      <IntroBanner imageUrl={'/assets/img/products/black.jpg'} title={"Our Product"} subtext={"Black Masterbatches Deep Color, Superior Performance"} textAvatar="BM" />
      <div className="container">
        <div className='row gx-80 masterclass-container '>
          <div className='col-md-8'>
            <div >
              <div className='title-container'>
                <h3 className='title'>Wide Range of Black Masterbatches</h3>
              </div>
              <div className='subtitle subtitle-padding'>
                <p className='subtitle_text' style={{ fontSize: "16px" }}>Alok’s range of Black Masterbatches has set a new benchmark in the plastics industry, becoming the gold standard for quality and performance. Designed to meet the evolving needs of modern applications, our Black Masterbatches combine cutting-edge innovation with unmatched reliability, making them the preferred choice for manufacturers worldwide.

At Alok, we understand the growing black masterbatches demands of the industry, and we have engineered high-performance black masterbatches that deliver exceptional results. These masterbatches are renowned for their superior filter life, ensuring enhanced processing efficiency and consistent output. Whether you are looking for solutions in packaging, automotive, or construction, our black masterbatches provide optimal performance across a wide range of applications.

In addition to performance, we prioritize safety and compliance by adhering to the most stringent regulatory standards. Our black masterbatches are especially suitable for critical applications such as potable water management, where durability, safety, and compliance are non-negotiable.

With Alok’s black masterbatches, you not only achieve excellence in functionality but also ensure sustainability and quality in every end product.</p>
              </div>

              <div className='title-container pt-80'>
                <h3 className='title'>Black Masterbatches</h3>
              </div>
              <div className='subtitle subtitle-padding'>
                <p className='subtitle_text' style={{ fontSize: "16px" }}>Alok goes beyond all regulatory compliance standards to deliver the finest black masterbatches in the industry. Engineered for efficiency, our Black Masterbatches feature an advanced filtration system that ensures superior performance. This commitment to quality and innovation has earned Alok recognition as a center of excellence in the plastics industry.</p>
              </div>

              <div className=" ">
                <div className="row">
                  <div className="col overflow-auto">
                    <table className="table table-bordered masterclass-table">
                      <thead>
                        <tr className="table-header" style={{ backgroundColor: 'black', color: 'white' }}>
                          <th scope="col">Products</th>
                          <th scope="col">Description</th>
                          <th scope="col">Application</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>High Jetness Black</td>
                          <td>Offers exceptional Jetness with excellent dispersion and coverage.</td>
                          <td>Ideal for premium packaging and high-end consumer products.</td>
                        </tr>
                        <tr>
                          <td>Conductive Carbon Black</td>
                          <td>Incorporates conductive fillers to provide electrical conductivity.</td>
                          <td>Used in electronics and automotive applications requiring static dissipation.</td>
                        </tr>
                        <tr>
                          <td>P Type Black</td>
                          <td>An extremely clean carbon black grade that does not alter the organoleptic properties.</td>
                          <td>Drink water storage and transportation</td>
                        </tr>
                        <tr>
                          <td>NIR Black</td>
                          <td>Designed for near-infrared applications, allowing recyclability of black plastics.</td>
                          <td>Suitable for sorting and recycling processes in waste management.</td>
                        </tr>
                        <tr>
                          <td>UV Stable Black</td>
                          <td>Includes additives that enhance UV resistance, protecting products from solar degradation.</td>
                          <td>Perfect for agricultural films and outdoor applications.</td>
                        </tr>
                        <tr>
                          <td>Food Contact Black</td>
                          <td>Complies with food safety standards for applications involving direct food contact.</td>
                          <td>Used in food packaging materials and utensils.</td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className='contact-card-btn'>
              <ProductContact />
            </div>
          </div>
          <div className='col-md-4 mobile-padding'>
            <ProductsSidebar />
          </div>
        </div>

      </div>
        {/* <OtherProducts/> */}
      <TopFooter />
      <Footer />
      {/* <IndustriesFooter/> */}
      </>
  )
}

export default Black