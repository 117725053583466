import React, { useEffect } from 'react';
import { Route, Routes } from "react-router-dom";
// internal
import BackToTop from '../components/BackToTop';
import AnimateMouse from '../components/common/AnimateMouse';
import ContextProvider from '../components/context/ContextProvider';
import Home from '../components/Home/Home';
import About from '../components/Pages/About/About';
import ContactUs from '../components/Pages/ContactUs/ContactUs';
import Industries from '../components/Pages/Industries/Industries';
import Products from '../components/Pages/Products/Products';
import Additive from '../components/Pages/MasterBatches/Additive';
import Agriculture from '../components/Pages/MasterBatches/Agriculture';
import Black from '../components/Pages/MasterBatches/Black';
import Fibre from '../components/Pages/MasterBatches/Fibre';
import Mineral from '../components/Pages/MasterBatches/Mineral';
import Recycling from '../components/Pages/MasterBatches/Recycling';
import UV from '../components/Pages/MasterBatches/UV';
import White from '../components/Pages/MasterBatches/White';
import Color from '../components/Pages/MasterBatches/Color';
import AgricultureIndustry from '../components/Pages/Industries/Agriculture';
import Automotive from '../components/Pages/Industries/Automotive';
import BuildingAndConstruction from '../components/Pages/Industries/BuildingAndConstruction';
import ConsumerDurables from '../components/Pages/Industries/ConsumerDurables';
import PackagingIndustry from '../components/Pages/Industries/PakagingIndustry';
import TextileAndFiber from '../components/Pages/Industries/TextileAndFibres';
import Sustainability from '../components/Pages/Sustainability/Sustainability';
import Regulatory from '../components/Pages/Regulatory';
import Careers from '../components/Pages/Careers/Careers';
import Privacy from '../components/Pages/Privacy/Privacy';
import TermsAndCondition from '../components/Pages/TermsAndCondition/TermsAndCondition';
import Csr from '../components/Pages/Corporate/CSR';
import Blog from '../components/Pages/Blog/Blog';
import BlogDetails from '../components/Pages/BlogDetails/BlogDetails';
// import Csr from '../components/Pages/Corporate/Csr';



const ScrollToHashElement = () => {
  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash;
      if (hash) {
        const element = document.getElementById(hash.substring(1));
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    };

    handleHashChange(); // Scroll on initial load
    window.addEventListener('hashchange', handleHashChange);
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  return null;
};

const AppNavigation = () => {
  return (
    <ContextProvider>
      <AnimateMouse/>
        {/* <ScrollToHashElement/> */}
      <Routes>
        <Route path="/"  element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact-us" element={<ContactUs/>} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/industries" element={<Industries />} />
        {/* <Route path="/blogs" element={<Blog />} />
        <Route path="/blogs/:slug" element={<BlogDetails />} /> */}
        <Route path="/industries/agriculture" element={<AgricultureIndustry />} />
        <Route path="/industries/automotive" element={<Automotive />} />
        <Route path="/industries/building-and-contruction-industry" element={<BuildingAndConstruction />} />
        <Route path="/industries/consumer-durables-industry" element={<ConsumerDurables />} />
        <Route path="/industries/packaging-industry" element={<PackagingIndustry />} />
        <Route path="/industries/textile-and-fibers-industry" element={<TextileAndFiber />} />
        <Route path="/sustainability" element={<Sustainability />} />
        <Route path="/regulatory" element={<Regulatory />} />
        <Route path="/industries" element={<Industries />} />
        <Route path="/products" element={<Products />} />
        <Route path="/additive-masterbatches" element={<Additive />} />
        <Route path="/agriculture-masterbatches" element={<Agriculture />} />
        <Route path="/black-masterbatches" element={<Black />} />
        <Route path="/color-masterbatches" element={<Color />} />
        <Route path="/fibre-masterbatches" element={<Fibre />} />
        <Route path="/mineral-masterbatches" element={<Mineral />} />
        <Route path="/recycling-masterbatches" element={<Recycling />} />
        <Route path="/uv-masterbatches" element={<UV />} />
        <Route path="/white-masterbatches" element={<White />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/terms-and-conditions" element={<TermsAndCondition />} />
        <Route path="/csr" element={<Csr />} />

        {/* <Route path="/team" element={<TeamPage/>} />
        <Route path="/team-details" element={<TeamDetails />} />
        <Route path="/service" element={<Service />} />
        <Route path="/service-details" element={<ServiceDetails />} />
        <Route path="/price" element={<Price/>} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/portfolio-details" element={<PortfolioDetails />} /> */}
      </Routes>
      <BackToTop/>
    </ContextProvider>
  );
};

export default AppNavigation;