import React from 'react'
import Menu from '../../common/Menu/Menu'
import TopFooter from '../../common/TopFooter/TopFooter'
import Footer from '../../common/Footer/Footer'
import IntroBanner from '../../common/IntroBanner'
import ProductContact from './ProductContact'
import ProductsSidebar from '../Products/ProductsSideBar'
import OtherProducts from '../Products/OurProducts'
import IndustriesFooter from '../Industries/IndustriesFooter'
import Meta from '../../Meta'

function Agriculture() {
  return (
      <>
      <Meta
                  title="Masterbatches for Agriculture and Horticulture - Alok Masterbatches"
                  description="Alok Masterbatches offers top-quality Masterbatches for Agriculture and Horticulture, recognized for innovation in enhancing crop protection and boosting productivity."
                />
      <Menu />

      <IntroBanner imageUrl={'/assets/img/industries/agriculture.jpg'} title={"Our Product"} subtext={"Masterbatches for Agriculture & Horticulture Cultivating Success with Advanced Solutions"} textAvatar="AH"/>
      <div className="container">
        <div className='row gx-80 masterclass-container '>
          <div className='col-md-8'>
            <div >
              <div className='title-container'>
                <h3 className='title'>Largest Manufacturers of Masterbatches for Agriculture & Horticulture</h3>
              </div>
              <div className='subtitle subtitle-padding'>
                <p className='subtitle_text' style={{ fontSize: "16px" }}>ALOK Masterbatches has been recognized with the National Award for Innovation for its outstanding contributions to the Indian agricultural sector. Our extensive R&D efforts, spanning four years and involving direct collaborations with farmers and industry stakeholders, have led to the development of a sophisticated range of Masterbatches specifically tailored for agricultural films. These products are designed to <a href="https://alokmasterbatches.com/black-masterbatches" target="_blank">enhance the efficiency and functionality</a> of agricultural applications, ensuring better crop protection and increased agricultural productivity.</p>
              </div>

              <div className='title-container'>
                <h3 className='title'>Masterbatches for Agriculture & Horticulture</h3>
              </div>
              <div className='subtitle subtitle-padding'>
                <p className='subtitle_text' style={{ fontSize: "16px" }}><a href="https://alokmasterbatches.com/" target="_blank">ALOK Masterbatches</a>
 ensure enhanced crop protection and increased agricultural productivity. Our solutions are tailored to meet the specific needs of the agricultural sector, contributing to long-term sustainability.</p>
              </div>

              <div className=" ">
                <div className="row">
                  <div className="col overflow-auto">
                    <table className="table table-bordered masterclass-table">
                      <thead>
                        <tr className="table-header" style={{ backgroundColor: 'black', color: 'white' }}>
                          <th scope="col">Products</th>
                          <th scope="col">Description</th>
                          <th scope="col">Application</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Anti-Block</td>
                          <td>Reduces the tendency of film layers to stick together, easing handling and application.</td>
                          <td>Used in greenhouse and mulch films.</td>
                        </tr>
                        <tr>
                          <td>Anti Dust</td>
                          <td>Coating that minimizes dust accumulation on film surfaces, maintaining clarity and light transmission.</td>
                          <td>Applied to greenhouse films to keep them clean and clear.</td>
                        </tr>
                        
                        <tr>
                          <td>Anti Insect</td>
                          <td>Incorporates UV inhibitors that deter insects and reduce the risk of crop diseases.</td>
                          <td>Ideal for greenhouse films to protect crops from pests.</td>
                        </tr>
                        
                        <tr>
                          <td>Anti Fog</td>
                          <td>Prevents water droplet formation inside greenhouses, enhancing light transmission and reducing disease risk.</td>
                          <td>Commonly used in greenhouse films.</td>
                        </tr>
                        
                        <tr>
                          <td>Black</td>
                          <td>Provides excellent opacity to suppress weed growth and conserve soil moisture.</td>
                          <td>Used in mulch films for effective ground cover.</td>
                        </tr>
                        
                        <tr>
                          <td>Diffuser</td>
                          <td>Scatters sunlight evenly, preventing plant burn and promoting uniform plant growth.</td>
                          <td>Essential for greenhouse films to optimize light distribution.</td>
                        </tr>
                        
                        <tr>
                          <td>IR Thermic</td>
                          <td>Reflects IR radiation, helping to maintain thermal balance within greenhouses.</td>
                          <td>Used in films to control the greenhouse climate overnight.</td>
                        </tr>
                        
                        <tr>
                          <td>Nickel</td>
                          <td>Offers superior resistance to chemicals and UV light, enhancing film durability.</td>
                          <td>Specially designed for rose greenhouses where chemical exposure is high.</td>
                        </tr>
                        
                        <tr>
                          <td>PPA</td>
                          <td>Enhances the extrusion process, preventing melt fracture and ensuring smoother film production.</td>
                          <td>Useful in both greenhouse and mulch film manufacturing.</td>
                        </tr>
                        
                        <tr>
                          <td>Silver</td>
                          <td>Reflects light and repels insects, providing dual benefits for mulch applications.</td>
                          <td>Used in mulch films to enhance light utilization and pest control.</td>
                        </tr>
                        
                        <tr>
                          <td>UvNox O</td>
                          <td>General-purpose UV stabilizer that also offers moderate pesticide resistance.</td>
                          <td>Applied in mulch and clear tunnel films for durability and protection.</td>
                        </tr>
                        
                

                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className='contact-card-btn'>
              <ProductContact />
            </div>
          </div>
          <div className='col-md-4 mobile-padding'>
            <ProductsSidebar />
          </div>
        </div>

      </div>
        {/* <OtherProducts/> */}
      <TopFooter />
      <Footer />
      {/* <IndustriesFooter/> */}
      </>
  )
}

export default Agriculture