import React from 'react'
import Menu from '../../common/Menu/Menu'
import TopFooter from '../../common/TopFooter/TopFooter'
import Footer from '../../common/Footer/Footer'
import IntroBanner from '../../common/IntroBanner'
import ProductContact from './ProductContact'
import ProductsSidebar from '../Products/ProductsSideBar'
import OtherProducts from '../Products/OurProducts'
import IndustriesFooter from '../Industries/IndustriesFooter'
import Meta from '../../Meta'

function White() {
  return (
      <>
      <Meta
                  title="White Masterbatch - White Masterbatch Suppliers"
                  description="Explore premium White Masterbatches with up to 75% TiO2 for excellent opacity, dispersion, and consistency. Ideal for various industries."
                />
      <Menu />

      <IntroBanner imageUrl={'/assets/img/products/white.jpg'} title={"Our Product"} subtext={"White Masterbatches Unleash Pure Brilliance Across Applications"} textAvatar="WM" />
      <div className="container">
        <div className='row gx-80 masterclass-container '>
          <div className='col-md-8'>
            <div >
              <div className='title-container'>
                <h3 className='title'>Wide Range of White Masterbatches</h3>
              </div>
              <div className='subtitle subtitle-padding'>
                <p className='subtitle_text' style={{ fontSize: "16px" }}>ALOK’s White Masterbatches offer unparalleled performance with up to 75% titanium dioxide (TiO2) concentrations, ensuring high opacity, excellent dispersion, and consistency. These <a href="https://alokmasterbatches.com/agriculture-masterbatches" target="_blank">masterbatches are formulated to meet the demanding requirements</a>
 of various industries, delivering superior quality and enhanced processing capabilities.</p>
              </div>

              <div className='title-container pt-80'>
                <h3 className='title'>White Masterbatches</h3>
              </div>
              <div className='subtitle subtitle-padding'>
                <p className='subtitle_text' style={{ fontSize: "16px" }}>ALOK deliver high opacity, excellent dispersion, and superior processing capabilities for white masterbatches. We meet the demanding requirements of various industries with unparalleled quality.</p>
              </div>

              <div className=" ">
                <div className="row">
                  <div className="col overflow-auto">
                    <table className="table table-bordered masterclass-table">
                      <thead>
                        <tr className="table-header" style={{ backgroundColor: 'black', color: 'white' }}>
                          <th scope="col">Products</th>
                          <th scope="col">Description</th>
                          <th scope="col">Application</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Standard White</td>
                          <td>Features high TiO2 loadings for exceptional brightness and opacity.</td>
                          <td>Ideal for profiles, pipes, and sheet extrusions.</td>
                        </tr>
                        <tr>
                          <td>Non-Lacing White</td>
                          <td>Specially designed to prevent lacing during high-temperature processing.</td>
                          <td>Used in injection molding and blow molding applications.</td>
                        </tr>
                        <tr>
                          <td>Food Grade White</td>
                          <td>Complies with strict food safety regulations for direct food contact.</td>
                          <td>Perfect for food packaging and consumer goods.</td>
                        </tr>
                        <tr>
                          <td>Weather Fast White</td>
                          <td>Engineered to maintain whiteness and resist UV-induced yellowing.</td>
                          <td>Suitable for outdoor applications and long-term exposure environments.</td>
                        </tr>
                        <tr>
                          <td>Multilayer films</td>
                          <td>Industry-standard white masterbatch (MB) grades that are specifically designed for high-speed 3, 5, 7, and 9-layer multilayer plants.</td>
                          <td>White masterbatch grades used in packaging, agricultural, and industrial films</td>
                        </tr>
                        {/* <tr>
                          <td>Weather Fast White</td>
                          <td>Offers enhanced weather resistance for products exposed to outdoor conditions.</td>
                          <td>Used in building and construction materials like siding and fencing.</td>
                        </tr> */}

                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className='contact-card-btn'>
              <ProductContact />
            </div>
          </div>
          <div className='col-md-4 mobile-padding'>
            <ProductsSidebar />
          </div>
        </div>

      </div>
        {/* <OtherProducts/> */}
      <TopFooter />
      <Footer />
      {/* <IndustriesFooter/> */}
      </>
  )
}

export default White