import React from 'react'

function IntroBanner({ imageUrl, title, subtext, textAvatar }) {
    return (
        <>
            <section
                className="breadcrumb__area include-bg about_banner product-banner"
                style={{
                    height: '500px',
                    background: `linear-gradient(90deg, #000 0%, rgba(0, 0, 0, 0.00) 100%), url(${imageUrl}) lightgray 50% / cover no-repeat`
                }}
            >
                <div className="overlay h-100 d-flex align-items-center">
                    <div className="container">
                        <div className='banner-details'>
                                    <h6 className="title mb-0">{title}</h6>
                                    <div className='subtitle-container'>
                                        <p className='mb-0 text-avatar'>
                                        {textAvatar || "AM"}
                                        </p>
                                        <h1 className='subtitle mb-0'>{subtext}</h1>
                                    </div>
                        </div>
                        
                    </div>
                </div>
            </section>
        </>
    )
}

export default IntroBanner