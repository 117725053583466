import React from 'react'
import Menu from '../../../common/Menu/Menu'
import TopFooter from '../../../common/TopFooter/TopFooter'
import Footer from '../../../common/Footer/Footer'
import Applications from './Applications'
import OtherIndustries from '../OtherIndustries'
import ContactCard from './ContactCard'
import HeroIndustries from './HeroIndustries'
import Meta from '../../../Meta'

function AgricultureIndustry() {
  return (
      <>
      <Meta
                  title="Corporate Social Responsibility Policy - Alok Masterbatches"
                  description="Learn about Alok Masterbatches' Corporate Social Responsibility (CSR) policies, including healthcare, education, and sustainable development initiatives."
                />
          <Menu />
          <HeroIndustries imageUrl={'/assets/img/industries/agriculture.jpg'} title={"Our Industries"} subtext={"Growing Success with Advanced Agricultural Masterbatches"} />
          <Applications/>
          <OtherIndustries/>
          <ContactCard/>
          <TopFooter />
          <Footer />
      </>
  )
}

export default AgricultureIndustry